.el-message-box {
    $block-name: &;
    &__title {
        padding-right: 25px;
        font-weight: 500;
    }
    &__headerbtn {
        #{$block-name} {
            &__close {
                width: 20px;
                height: 20px;
                color: $dark;
                svg {
                    width: 100% !important;
                    max-width: 100% !important;
                    height: 100% !important;
                    max-height: 100% !important;
                }
            }
        }
    }
    &__message {
        p {
            font-size: 16px;
            color: $dark;
        }
    }
}
