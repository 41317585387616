@import "resources/sass/_variables.scss";
.el-sub-menu {
    $block-name: &;
    &__title {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        transition: 0.25s color ease-in-out;
        &:hover {
            background-color: unset;
            .el-menu-item {
                &__name {
                    &:before {
                        background-color: $dark-middle;
                    }
                }
            }
        }
        #{$block-name}__icon-arrow {
            font-size: 16px;
            transform: rotate(-90deg) !important;
        }
    }
    &.is-opened >{
        #{$block-name}__title {
            font-weight: bold;
            color: $indigo !important;
            #{$block-name}__icon-arrow {
                stroke: $indigo;
                stroke-width: 80px;
                transform: rotate(0) !important;
            }
            &:hover {
                background-color: unset;
                .el-menu-item {
                    &__name {
                        &:before {
                            background-color: $indigo;
                        }
                    }
                }
            }
        }
        .el-menu {
            .el-sub-menu {
                &__title {
                    &:hover {
                        color: $indigo !important;
                    }
                }
            }
        }
    }
    &--companies {
        .el-menu {
            #{$block-name} {
                &__title {
                    &-name {
                        margin-left: -10px;
                        border-left: 1px solid $light-gray;
                        overflow: hidden;
                        padding-right: 15px;
                        padding-left: 10px;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
    &--products {
        max-height: calc(100% - 100px);
        .el-menu {
            overflow-x: hidden !important;
            overflow-y: auto !important;
            max-height: calc(100% - 32px);
        }
        >.el-menu {
            #{$block-name} {
                &__title {
                    &-name {
                        margin-left: -10px;
                        border-left: 1px solid $light-gray;
                        overflow: hidden;
                        padding-right: 15px;
                        padding-left: 10px;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        .el-menu {
            .el-menu {
                .el-menu {
                    .el-menu {
                        #{$block-name} {
                            &__title {
                                #{$block-name} {
                                    &__icon-arrow {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &--companies,
    &--products {
        .el-sub-menu {
            &__title {
                .el-menu-item__icon {
                    display: none;
                }
            }
        }
    }
    &--popper {
        .el-menu {
            .el-sub-menu {
                &[aria-expanded="false"],
                &[aria-expanded="true"] {
                    &:hover {
                        .el-popper {
                            background: transparent;
                            border-color: transparent;
                        }
                    }
                }
                .el-popper.is-light {
                    background: transparent;
                    border-color: transparent;
                }
            }
            .el-sub-menu.is-opened {
                .el-sub-menu__level-3 {
                    .el-menu {
                        .el-sub-menu {
                            &__title-name {
                                max-width: 160px;
                            }
                            &__icon-arrow {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        #{$block-name} {
            &__title {
                #{$block-name} {
                    &__icon-arrow {
                        transform: unset !important;
                    }
                }
                &-name {
                    max-width: 145px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            &__level-3 {
                #{$block-name} {
                    &.is-opened >{
                        #{$block-name}__icon-arrow {
                            display: none;
                        }
                        .el-popper {
                            display: none;
                        }
                    }
                }
            }
            &.is-opened >{
                #{$block-name}__title {
                    #{$block-name}__icon-arrow {
                        transform: rotate(90deg) !important;
                    }
                }
            }
        }
    }
}
