.el-radio {
    $block-name: &;
    height: unset;
    margin-right: unset;
    white-space: normal;
    &__input {
        &.is-checked {
            #{$block-name} {
                &__inner {
                    background-color: transparent;
                }
            }
            & + #{$block-name}__label {
                color: $dark;
            }
        }
    }
    &__inner {
        width: 20px;
        height: 20px;
        border: 2px solid $gray;
        box-shadow: none;
        &:after {
            width: 10px;
            height: 10px;
            background-color: $indigo;
        }
    }
    &__label {
        padding-left: 26px;
        font-size: 16px;
        font-weight: 400;
        color: $dark;
        strong {
            font-weight: 600;
        }
        &-sub {
            &-text {
                font-size: 15px;
                color: $gray;
            }
            &-icon {
                width: 20px;
                height: 20px;
                padding: 0 5px;
                vertical-align: sub;
                &-svg {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
    &:focus:not(.is-focus):not(:active):not(.is-disabled) {
        #{$block-name}__inner {
            box-shadow: none;
        }
    }
    &-group-bordered {
        &.radio-block {
            #{$block-name} {
                display: flex;
                align-items: flex-start;
                flex-grow: 1;
                height: unset;
                margin-right: 0;
                border: 1px solid transparent;
                border-radius: 24px;
                padding: 12px 18px;
                line-height: 1.5;
                white-space: normal;
                overflow: hidden;
                outline: none;
                transition: 0.2s border-color ease-in-out, 0.2s background-color ease-in-out;
                &__input {
                    padding-top: 2px;
                }
                &.is-checked {
                    border: 1px solid $indigo;
                    border-radius: 24px;
                    background-color: #eef1ff;
                    #{$block-name}__inner {
                        background: $white;
                    }
                    #{$block-name}__label {
                        color: $dark;
                    }
                }
            }
        }
    }
    &-group {
        &__item {
            margin: 0 0 25px;
            &-checkbox-group {
                padding: 20px 0 0 46px;
            }
        }
    }
}
