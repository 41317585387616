.el-popper {
    $sm: 660px;
    &--cart-product-block {
        max-width: 320px;
        padding: 15px;
        box-shadow: 0 0 10px 1px rgba(0,0,0,0.25);
        .el-popconfirm {
            &__main {
                align-items: flex-start;
                font-size: 16px;
            }
            &__action {
                .el-button {
                    border-radius: 18px;
                }
            }
        }
    }
    &--companies, &--stores, &--columns {
        .el-select-dropdown {
            &__item {
                height: 28px;
                padding: 0 15px 0 45px;
                font-size: 12px;
                line-height: 28px;
                &:before {
                    content: "";
                    position: absolute;
                    top: calc(50% - 8px);
                    left: 15px;
                    width: 16px;
                    height: 16px;
                    border-radius: 8px;
                    border: 1px solid $dark-middle;
                    transition: 0.25s border-color ease-in-out, 0.25s font-weight ease-in-out;
                }
                span {
                    color: $dark-middle;
                    transition: 0.25s color ease-in-out;
                }
            }
            &.is-multiple {
                .el-select-dropdown{
                    &__item {
                        &.selected {
                            &:after {
                                right: auto;
                                left: 17px;
                                background-color: $indigo;
                            }
                            &:before {
                                border-color: $indigo;
                            }
                            span {
                                font-weight: 500;
                                color: $indigo;
                            }
                        }
                    }
                }
            }
        }
    }
    &--stores {
        width: 360px;
        .el-select-dropdown {
            width: 350px !important;
            &__item {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                &-name {
                    font-size: 13px;
                }
                &-address {
                    font-size: 13px;
                    color: $gray;
                }
            }
        }
    }
    &--date-picker-period {
        @media all and (max-width: $sm - 1) {
            width: 98%;
            max-width: 480px;
        }
        .el-date-range-picker {
            @media all and (max-width: $sm - 1) {
                width: auto;
            }
            .el-picker-panel__body {
                @media all and (max-width: $sm - 1) {
                    min-width: unset
                }
                .el-date-range-picker__content {
                    @media all and (max-width: $sm - 1) {
                        float: unset;
                        width: auto;
                        &.is-left {
                            border-right: unset;
                        }
                    }
                }
            }
        }
        .el-date-table td.start-date .el-date-table-cell__text,
        .el-date-table td.end-date .el-date-table-cell__text {
            background-color: $green;
        }
    }
    &--companies {
        .el-menu {
            .el-sub-menu {
                $block-name: &;
                &__title {
                    .el-sub-menu__icon-arrow {
                        display: none;
                    }
                    &-name {
                        max-width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                &[aria-expanded="false"],
                &[aria-expanded="true"] {
                    &:hover {
                        .el-popper {
                            background: transparent;
                            border-color: transparent;
                        }
                    }
                }

                .el-popper.is-light {
                    background: transparent;
                    border-color: transparent;
                }
            }
        }
    }
}
