@import "resources/sass/_variables.scss";
.el-menu {
    &-item {
        height: 32px;
        font-size: 12px;
        line-height: 32px;
        &:hover {
            background-color: unset;
            .el-menu-item {
                &__name {
                    &:before {
                        background-color: $dark-middle;
                    }
                }
            }
        }
        &__icon {
            display: none;
        }
        &__name {
            position: relative;
            font-size: 12px;
            &:before {
                content: "";
                position: absolute;
                top: auto;
                right: 0;
                bottom: 8px;
                left: 0;
                height: 1px;
                background-color: transparent;
                transition: 0.25s background-color ease-in-out;
            }
        }
        &.is-active {
            font-weight: bold;
            &:hover {
                .el-menu-item {
                    &__name {
                        &:before {
                            background-color: $indigo;
                        }
                    }
                }
            }
        }
    }
    &--collapse {
        .el-menu-item {
            justify-content: center;
            &__name {
                margin-left: 0;
            }
            &__icon {
                display: block;
                width: 18px !important;
                height: 18px !important;
                &-svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .el-sub-menu {
            &--companies,
            &--products {
                .el-sub-menu__title {
                    .el-menu-item__icon {
                        display: block;
                        font-size: 17px;
                    }
                }
            }
        }
    }
}
