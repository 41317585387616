.el-tag {
    $block-name: &;
    background-color: $indigo;
    &__content {
        .el-select {
            &__tags-text {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $white;
            }
        }
    }
}
