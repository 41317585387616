.default-text {
    font-family: $avenir-next;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.44px;
    color: $dark;
    &--offset-bottom {
        margin-bottom: 10px;
    }
    &--bold {
        font-weight: 600;
    }
    &--align-right {
        text-align: right;
    }
}
