@import "resources/sass/_variables.scss";
.el-date-picker {
    background-color: transparent;
    user-select: none;
    &.el-date-editor {
        height: 24px;
        font-size: 12px !important;
        line-height: 2;
        .el-range {
            &__icon {
                height: 24px;
                margin-right: 5px;
                color: $dark-middle;
            }
            &-input {
                max-width: 70px;
                height: 24px;
                font-family: $avenir-next;
                font-size: 12px;
                font-weight: 600;
                line-height: 2;
                color: $dark-middle;
                &::placeholder {
                    color: $dark-middle;
                }
            }
            &-separator {
                flex: unset;
                font-size: 12px;
                font-weight: 600;
                line-height: 2;
            }
            &__close-icon {
                margin-left: 5px;
                color: $dark-middle;
            }
        }
        &.el-input {
            &__wrapper {
                box-shadow: unset;
            }
        }
        &--daterange {
            width: auto;
        }
    }
    &.el-input__wrapper {
        padding: 0;
    }
    &--no-grow {
        flex-grow: 0;
    }
}
